<!--
<section class="mt-5">

<backstage-layout-grid [gap]="4">

<backstage-layout-grid-cell [span]="{xs: 12, md: 6, lg: 4}">
  <app-dashboard-link-card icon="identity-cloud">
    <div title>PingOne <br> Advanced Identity Cloud</div>
    <div tagline>The True Identity Platform Delivered as a Service</div>
    <div content>
      @if ('product:cloud' | hasRole) {
        <a href="/downloads/browse/identity-cloud/featured">Downloadable Components</a>
      }
      <a href="/docs/idcloud/latest/index.html">Docs</a>
      <a href="/knowledge/kb/home/identity-cloud">Knowledge Base</a>
    </div>
  </app-dashboard-link-card>
</backstage-layout-grid-cell>
<backstage-layout-grid-cell [span]="{xs: 12, md: 6, lg: 4}">
  <app-dashboard-link-card icon="platform-icon">
    <div title>Ping <br> Identity Platform</div>
    <div tagline>One Platform. All Identities.</div>
    <div content>
      <a href="/downloads">Downloads</a>
      <a href="/docs">Docs</a>
      <a href="/knowledge/kb/home">Knowledge Base</a>
    </div>
  </app-dashboard-link-card>
</backstage-layout-grid-cell>
<backstage-layout-grid-cell [span]="{xs: 12, md: 6, lg: 4}">
  <app-dashboard-link-card imageUrl="logo/ping/Logo-PingIdentity-200x200.png">
    <div title>Ping<br/>Identity</div>
    <div tagline>Everything you need for Ping product success</div>
    <div content>
      <a
        href="https://docs.pingidentity.com"
        target="_blank"
        rel="nofollow">Docs</a>
      <a
        href="https://support.pingidentity.com"
        target="_blank"
        rel="nofollow">Support</a>
    </div>
  </app-dashboard-link-card>
</backstage-layout-grid-cell>

</backstage-layout-grid>
</section>
-->
<section class="mt-5">
  <backstage-layout-grid [gap]="4">
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
        href="/support">
        <backstage-icon
          size="4rem"
          iconId="backstage/support"
          class="d-block"/>
        Support
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
        href="/downloads">
        <backstage-icon
          size="4rem"
          iconId="backstage/downloads"
          class="d-block"/>
        Downloads
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
        href="/docs">
        <backstage-icon
          size="4rem"
          iconId="backstage/docs"
          class="d-block"/>
        Docs
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
        href="/knowledge">
        <backstage-icon
          size="4rem"
          iconId="backstage/knowledge"
          class="d-block"/>
        Knowledge
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
        href="/university">
        <backstage-icon
          size="4rem"
          iconId="backstage/university"
          class="d-block"/>
        Training
      </a>
    </backstage-layout-grid-cell>
    @if ([ 'customer', 'contractor', 'internal' ] | hasRole) {
      <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
        <a
          class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
          href="/cloudstorage">
          <backstage-icon
            size="4rem"
            iconId="open-standards"
            class="d-block"/>
          Cloud Storage
        </a>
      </backstage-layout-grid-cell>
    }
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
        href="/marketplace">
        <backstage-icon
          size="4rem"
          iconId="backstage/marketplace"
          class="d-block"/>
        Marketplace
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
        href="https://community.forgerock.com">
        <backstage-icon
          size="4rem"
          iconId="backstage/forums"
          class="d-block"/>
        Community
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn rounded-xl btn-lg btn-white btn-block pt-3"
        href="/community/developer">
        <backstage-icon
          size="4rem"
          iconId="backstage/source-code"
          class="d-block"/>
        Developers
      </a>
    </backstage-layout-grid-cell>
  </backstage-layout-grid>
</section>
