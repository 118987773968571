import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { toHttpParams, WINDOW } from '@forgerock/backstage-ui-core';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: [ './search-bar.component.scss' ]
})
export class SearchBarComponent {
  query: string;
  searchTab = 'all';
  searchTabs = [ 'all', 'pages', 'videos', 'issues', 'community', 'training' ];

  constructor(@Inject(WINDOW) private window: Window,
              @Inject(DOCUMENT) private document: Document) {}


  redirectToSearchUi(): void {
    const httpParams = toHttpParams({ q: this.query, t: this.searchTab });
    this.window.location.assign(`/search?${httpParams}`);
  }

}
