<form
  name="searchForm"
  (submit)="redirectToSearchUi()">
  <div class="container">
    <div class="row my-3">
      <div class="col ps-0 pe-2 d-flex">
        <div
          class="input-group"
          ngbDropdown>
          <div class="input-group-text border-0 bg-gray-100 rounded-xl"><i class="bi-search"></i></div>
          <button
            type="button"
            class="btn btn-gray-100 btn-lg btn-reset text-capitalize d-none d-sm-block z-index-10"
            ngbDropdownToggle>{{searchTab}}</button>
          <div
            ngbDropdownMenu
            class="shadow">
            <button
              ngbDropdownItem
              type="button"
              class="text-capitalize"
              *ngFor="let tab of searchTabs"
              (click)="searchTab = tab">{{tab}}</button>
          </div>
          <label
            for="query"
            class="visually-hidden">Query</label>
          <input
            id="query"
            type="text"
            autofocus
            tabindex="1"
            autocomplete="off"
            class="form-control form-control-lg"
            placeholder="Search ForgeRock"
            name="query"
            [(ngModel)]="query">
        </div>
        <button
          type="submit"
          class="go-button btn btn-lg btn-copper bg-copper_to_brass shadow-sm px-4 ms-n4 z-index-10 rounded-xl text-white"
          tabindex="2"><strong>Go</strong>
        </button>
      </div>
    </div>
  </div>
</form>
