<app-card className="bg-white shadow-sm border">
  <header class="text-success">{{ result.title }}</header>
  <main class="row ps-3 text-wrap">
    <div class="col-md-4 ps-0">
      <img
        class="img-thumbnail bg-white border-0"
        (error)="error = true"
        [src]="error ? fallbackThumbnailUrl : result.thumbnailUrl"
        [alt]="result.title">
    </div>
    <div class="col-md-8 ps-0">
      <backstage-ellipsis
        [length]="150"
        [content]="result.synopsis"></backstage-ellipsis>
    </div>
  </main>
  <footer class="d-flex align-items-center">
    <div>
      <div
        *ngFor="let tag of result.tags"
        class="badge text-bg-gray-200 me-1">{{ tag }}
      </div>
    </div>
    <div class="ms-auto">
      <a
        href="/marketplace/entry/{{result.externalId}}"
        class="btn btn-success text-nowrap">
        <i class="bi-arrow-right-circle-fill"></i>
        <ng-container i18n> Details</ng-container>
      </a>
    </div>
  </footer>
</app-card>
