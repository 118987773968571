import { NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  EllipsisComponent,
  IconComponent, LayoutGridCellComponent,
  LayoutGridComponent
} from '@forgerock/backstage-ui-components';
import { APP_NAME, UiCoreModule } from '@forgerock/backstage-ui-core';
import {
  NgbNavModule,
  NgbPaginationModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
  NgbDropdownModule
} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './card/card.component';
import { DashboardAdminPanelComponent } from './dashboard-admin-panel/dashboard-admin-panel.component';
import {
  DashboardDownloadsPanelButtonComponent
} from './dashboard-downloads-panel-button/dashboard-downloads-panel-button.component';
import { DashboardKbPanelComponent } from './dashboard-kb-panel/dashboard-kb-panel.component';
import { DashboardLinkCardComponent } from './dashboard-link-card/dashboard-link-card.component';
import { DashboardLinksPanelComponent } from './dashboard-links-panel/dashboard-links-panel.component';
import {
  DashboardMaintenanceInfoPanelComponent
} from './dashboard-maintenance-info-panel/dashboard-maintenance-info-panel.component';
import {
  DashboardMarketplacePanelComponent
} from './dashboard-marketplace-panel/dashboard-marketplace-panel.component';
import { DashboardSupportPanelComponent } from './dashboard-support-panel/dashboard-support-panel.component';
import { DashboardUniversityPanelComponent } from './dashboard-university-panel/dashboard-university-panel.component';
import {
  DashboardVerifyEmailPanelComponent
} from './dashboard-verify-email-panel/dashboard-verify-email-panel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorComponent } from './error/error.component';
import { OrgCardComponent } from './org-card/org-card.component';
import { ProductLabelComponent } from './product-label/product-label.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { DashboardMarketplacePanelItemComponent } from './dashboard-marketplace-panel-item/dashboard-marketplace-panel-item.component';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
    DashboardAdminPanelComponent,
    DashboardComponent,
    DashboardDownloadsPanelButtonComponent,
    DashboardKbPanelComponent,
    DashboardLinksPanelComponent,
    DashboardMarketplacePanelComponent,
    DashboardMaintenanceInfoPanelComponent,
    DashboardSupportPanelComponent,
    DashboardUniversityPanelComponent,
    ErrorComponent,
    SearchBarComponent,
    ProductLabelComponent,
    OrgCardComponent,
    DashboardVerifyEmailPanelComponent,
    CardComponent,
    DashboardLinkCardComponent,
    DashboardMarketplacePanelItemComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbDropdownModule,
    UiCoreModule,
    AppRoutingModule,
    NgbNavModule,
    NgOptimizedImage,
    IconComponent,
    EllipsisComponent,
    LayoutGridComponent,
    LayoutGridCellComponent
  ],
  providers: [
    { provide: APP_NAME, useValue: 'bs_dashboard-ui' },
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}
