<div class="card bg-white-alpha-50 mt-4">
  <div class="card-body">
    <div class="row ps-3">
      <div class="col-sm-10 ps-0 mb-3">
        <h3 i18n
            class="display-6">
          <backstage-icon iconId="backstage/university"
                          class="grayscale"
                          size="2.5rem"></backstage-icon>
          Training
        </h3>
      </div>
      <div class="col-sm-2 ps-0 mb-3 text-sm-end">
        <a href="/university/learning-pass">
          <ng-container [ngSwitch]="activeLearningPassTier">
            <div *ngSwitchCase="learningPassTier.ALL_ACCESS"
                 class="badge text-uppercase rounded-50 px-2 py-1 shadow-sm bg-dark text-warning">
              All Access
            </div>
            <div *ngSwitchCase="learningPassTier.PRO_ACCESS"
                 class="badge text-uppercase rounded-50 px-2 py-1 shadow-sm bg-info">
              Pro Access
            </div>
            <div *ngSwitchDefault
                 class="badge text-uppercase rounded-50 px-2 py-1 shadow-sm bg-white text-dark border">
              Basic Access
            </div>
          </ng-container>
        </a>
      </div>
    </div>
    <p class="mb-4 lead text-dark"
       i18n>
      Determining how to architect, build, and deploy your identity solution doesn’t need to be a complex
      process, but needs to be done right. ForgeRock training combines training, enablement, and technology to arm you
      with the skills you need to execute a smooth deployment.
    </p>
    <div class="d-flex flex-wrap justify-content-around">
      <div class="ps-0 mb-3">
        <a class="btn rounded-pill btn-outline-primary btn-lg text-nowrap"
           href="/university/on-demand">On-Demand Learning</a>
      </div>
      <div class="ps-0 mb-3"
           *ngIf="'partner' | hasRole">
        <a class="btn rounded-pill btn-outline-primary btn-lg text-nowrap"
           href="/university/academy">Partner Academy</a>
      </div>
      <div class="ps-0 mb-3"
           *ngIf="'partner' | hasRole">
        <a class="btn rounded-pill btn-outline-primary btn-lg text-nowrap"
           href="/university/selfpaced"
           i18n>Self Paced Learning</a>
      </div>
      <div class="ps-0 mb-3">
        <a class="btn rounded-pill btn-outline-primary btn-lg text-nowrap"
           href="/university/events"
           i18n>Event Calendar</a>
      </div>
      <div class="ps-0 mb-3">
        <a class="btn rounded-pill btn-outline-primary btn-lg text-nowrap"
           href="/university/courses"
           i18n>Courses</a>
      </div>
      <div class="ps-0 mb-3">
        <a class="btn rounded-pill btn-outline-primary btn-lg text-nowrap"
           href="/university/certification"
           i18n>Certification</a>
      </div>
      <div class="ps-0 mb-3"
           *ngIf="'partner' | hasRole">
        <a class="btn rounded-pill btn-outline-primary btn-lg text-nowrap"
           href="/university/accreditation"
           i18n>Accreditation</a>
      </div>
    </div>
    <hr>
    <div class="d-flex flex-wrap justify-content-around"
         *ngIf="'community' | hasRole">
      <a class="btn rounded-pill btn-info btn-lg me-3"
         href="/university/enrollments"
         i18n>My Enrollments</a>
      <a class="btn rounded-pill btn-info btn-lg"
         href="/university/orders"
         i18n>My Orders</a>
    </div>
  </div>
</div>
