<div
  class="card bg-white-alpha-50 mt-4"
  [@panelState]="('community' | hasRole) ? 'in' : 'out'">
  <div class="card-body">
    <h3
      i18n
      class="display-6 mb-3">
      <backstage-icon
        iconId="backstage/support"
        class="grayscale"
        size="2.5rem"></backstage-icon>
      Support
    </h3>
    <p
      class="mb-4 lead text-dark"
      i18n>
      Our biggest ambition is to help customers achieve theirs. Whether you’re kicking off a small identity project or
      rolling out an enterprise-wide deployment, ForgeRock offers products and services to help you achieve even your
      loftiest goals. We measure our outline-success with customer outline-success — not billable hours.
    </p>
    <div>
      <hr>
      <h4
        class="mb-3"
        library
        i18n>Subscriptions</h4>
      <div
        class="text-nowrap overflow-scroll"
        [@panelState]="(orgs | async)?.length ? 'in' : 'out'">
        <div
          class="col-md-5 d-inline-block mb-3 ps-0 text-wrap me-3"
          *ngFor="let org of orgs | async">
          <app-org-card [org]="org"></app-org-card>
        </div>
      </div>
      <div>
        Don't see your subscription listed here?
        <a
          href="/account/subscriptions#join"
          class="text-primary">Join a subscription <i class="bi-arrow-up-right-square"></i></a>
      </div>
    </div>
    <hr>
    <div [@panelState]="('customer' | hasRole) ? 'in' : 'out'">
      <h4
        class="mb-3"
        i18n>Resources</h4>
      <div class="row ps-3">
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/account/subscriptions"
            class="rounded-pill btn btn-success btn-lg">
            <i class="bi-building-fill"></i><br>
            <ng-container i18n>Subscriptions</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/support/tickets"
            class="rounded-pill btn btn-success btn-lg">
            <i class="bi-tags-fill"></i><br>
            <ng-container i18n>Tickets</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/support/issues"
            class="rounded-pill btn btn-success btn-lg">
            <i class="bi-bug-fill"></i><br>
            <ng-container i18n>Issue Tracking</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/cloudstorage/subscriptions?tag=customer-patch"
            class="rounded-pill btn btn-success btn-lg">
            <i class="bi-patch-check-fill"></i><br>
            <ng-container i18n>Patches</ng-container>
          </a>
        </div>
      </div>
      <h4
        class="mb-3"
        i18n>Open a Ticket</h4>
      <div class="row ps-3">
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/support/tickets/new/problemIncident"
            class="rounded-pill btn btn-warning btn-lg"
            placement="bottom"
            container="body"
            i18n-ngbTooltip
            ngbTooltip="An issue has occurred that needs to be resolved, e.g. users unable to login; reconciliation has stopped; instance will not start">
            <i class="bi-fire"></i><br>
            <ng-container i18n>Problem or Incident</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/support/tickets/new/howDoI"
            class="rounded-pill btn btn-warning btn-lg"
            placement="bottom"
            container="body"
            i18n-ngbTooltip
            ngbTooltip="A question needs to answered about how to approach a task, e.g. how to install; how to manage certificates; how to backup configuration data">
            <i class="bi-question-circle-fill"></i><br>
            <ng-container i18n>How Do I...?</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/support/tickets/new/subscriptionHelp"
            class="rounded-pill btn btn-warning btn-lg"
            placement="bottom"
            container="body"
            i18n-ngbTooltip
            ngbTooltip="A query about a non-product related issue, e.g. how to add a user to the subscription; how to renew a contract; how to engage with Professional Services">
            <i class="bi-person-circle"></i><br>
            <ng-container i18n>Subscription Help</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/support/tickets/new/enhancementRequest"
            class="rounded-pill btn btn-warning btn-lg"
            placement="bottom"
            container="body"
            i18n-ngbTooltip
            ngbTooltip="A part of the product could be improved, e.g. a suggestion about how improve logging; a proposal about how to refine a security component">
            <i class="bi-gift-fill"></i><br>
            <ng-container i18n>Enhancement Request</ng-container>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
