<div class="site-header">
  <div class="container">
    <div class="row py-5">
      <div class="col-12 py-4">
        <h1 class="display-3 mb-4 text-darkblue-200 text-center fw-semibold">Backstage</h1>
        <app-search-bar/>
      </div>
    </div>
  </div>
</div>
<app-dashboard-admin-panel/>
<div class="container pb-5">
  <div class="row">
    <div class="col-12">
      <app-dashboard-links-panel/>
      <app-dashboard-maintenance-info-panel/>
      <app-dashboard-verify-email-panel class="d-block"/>
      <app-dashboard-support-panel class="d-block"/>
      <app-dashboard-kb-panel class="d-block"/>
      <app-dashboard-university-panel class="d-block"/>
      <app-dashboard-marketplace-panel class="d-block"/>
    </div>
  </div>
</div>

