<div class="container pt-3">
  <div class="card bg-white mt-5 border-0 text-center">
    <div class="card-body">
      <h1 class="display-1 text-muted m-3 text-center">¯\_(ツ)_/¯</h1>
      <h1 class="display-4 mb-3 text-primary" [ngSwitch]="code">
        <ng-container *ngSwitchCase="ErrorCode.session_error" i18n>Your session has expired</ng-container>
        <ng-container *ngSwitchCase="ErrorCode.data_error" i18n>Invalid data received</ng-container>
        <ng-container *ngSwitchCase="ErrorCode.forbidden" i18n>You don't have access to this resource</ng-container>
        <ng-container *ngSwitchCase="ErrorCode.not_found" i18n>Resource not found (or confidential?!)</ng-container>
        <ng-container *ngSwitchCase="ErrorCode.email_not_verified" i18n>Unverified email address</ng-container>
        <ng-container *ngSwitchCase="ErrorCode.subscription_invalid" i18n>Invalid subscription</ng-container>
        <ng-container *ngSwitchDefault i18n>An error has occurred</ng-container>
      </h1>
      <p class="lead" [ngSwitch]="code">
        <ng-container *ngSwitchCase="ErrorCode.email_not_verified" i18n>
          Please verify your email address before
          proceeding by following the link in the verification email we sent you. If you haven't received this email,
          you can request a new verification token on your <a href="/account/profile">profile page</a>.
        </ng-container>
        <ng-container *ngSwitchCase="ErrorCode.subscription_invalid" i18n>
          The requested service can only be accessed with a valid subscription. If you have any concerns, please contact
          <a href="mailto:info@forgerock.com">info&#64;forgerock.com</a>
        </ng-container>
      </p>
    </div>
  </div>
  <div class="text-center mt-5">
    <a routerLink="/" class="btn btn-outline-secondary" i18n>Go to BackStage</a>
  </div>
</div>
